
<body>

  <div class=" example-container" [class.example-is-mobile]="mobileQuery.matches">

    <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">


      <mat-sidenav style="width: 250px;" *ngIf="loggedin" #sidenav class="sidenav nav-back-color mat-elevation-z8" [mode]="mobileQuery.matches ? 'over' : 'side'" [opened]="mobileQuery.matches ? false : true">

        <mat-nav-list>

          <div class="tin-input-row">


            <div class="col-4" style="margin-right: 10px;">

              <img src="assets/dummy.png" style="height: 60px; border-radius: 50%; " (click)="profile()"/>

            </div>

            <div class="col">

              <div class="tin-input-row">
                <label style="font-size: 13px; font-weight: 600;" (click)="profile()">{{loggedUserFullName}}</label>
              </div>

              <!-- <div class="tin-input-row">
                <a mat-button style="font-size: 13px;"  (click)="profile()">{{loggedUserFullName}}</a>
              </div> -->

              <!-- <div class="tin-input-row">
                <label style="font-size: 13px;">Tinashe</label>
              </div> -->


            </div>

          </div>

          <hr>





          <!-- wrap all the nav items in an accordion panel -->
          <mat-accordion displayMode="flat">

            <div *ngFor="let navItem of appConfig.capItems">


              <!-- use a simple div for an item that has no children, match up the styling to the expansion panel styles -->
              <div class="nav-head" style="margin-left: 25px;" *ngIf="!navItem.capSubItems && myRole[navItem.name]  && navItem.showMenu">
                <a class="nav-link"  [routerLink]="navItem.link" routerLinkActive="selected">

                  <div class="tin-input-row" style="margin-top:5px; margin-bottom: 5px;">
                    <mat-icon style="margin-right: 1em;" class="nav-icon-color">{{navItem.icon}}</mat-icon>
                    <span class="nav-link-text nav-font-color">{{navItem.display}}</span>
                  </div>

                </a>
              </div>


               <!-- use a simple div for an item that has children but want to ignore them, match up the styling to the expansion panel styles -->
               <div class="nav-head" style="margin-left: 25px;" *ngIf="navItem.capSubItems && myRole[navItem.name]  && navItem.showMenu && navItem.ignoreSubsDisplay">
                <a class="nav-link"  [routerLink]="navItem.link" routerLinkActive="selected">

                  <div class="tin-input-row" style="margin-top:5px; margin-bottom: 5px;">
                    <mat-icon style="margin-right: 1em;" class="nav-icon-color">{{navItem.icon}}</mat-icon>
                    <span class="nav-link-text nav-font-color">{{navItem.display}}</span>
                  </div>

                </a>
              </div>



              <!-- use expansion panel for heading item with sub page links -->
              <mat-expansion-panel  *ngIf="navItem.capSubItems && myRole[navItem.name] && navItem.showMenu && !navItem.ignoreSubsDisplay" class="mat-elevation-z0 nav-back-color">

                <mat-expansion-panel-header  class="nav-head" [expandedHeight]="'48px'">
                  <mat-panel-title  class="nav-link">
                    <mat-icon style="margin-right: 1em;" class="nav-icon-color">{{navItem.icon}}</mat-icon>
                    <span class="nav-link-text nav-font-color">{{navItem.display}}</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="nav-section">
                  <!-- loop through all your sub pages inside the expansion panel content -->
                  <div *ngFor="let navPage of navItem.capSubItems " class="nav-item nav-font-color" style="margin-left: 50px; ">
                    <a class="nav-link" [routerLink]="navPage.link" routerLinkActive="selected" *ngIf="myRole[navPage.name] && navPage.showMenu" >
                      {{navPage.display}}
                    </a>
                  </div>
                </div>
              </mat-expansion-panel>


            </div>

            <div class="nav-head" style="margin-left: 25px;" >
              <a class="nav-link" (click)="about()" routerLinkActive="selected">

                <div class="tin-input-row" style="margin-top:5px; margin-bottom: 5px;">
                  <mat-icon  style="margin-right: 1em;" class="nav-icon-color">info</mat-icon>
                  <span class="nav-link-text nav-font-color">About</span>
                </div>

              </a>
            </div>
          </mat-accordion>

        </mat-nav-list>
      </mat-sidenav>

      <mat-sidenav-content>



        <div class="container-fluid tin-back" >

        <mat-toolbar id="noprint" class="tin-back-toolbar"  *ngIf="loggedin"  >

          <button mat-icon-button (click)="toggle()" class="example-icon" aria-label="Example icon-button with menu icon"> <mat-icon>menu</mat-icon> </button>

          <img *ngIf="appConfig.logo!=''" [src]="appConfig.logo" style="height: 50px; margin-right: 1em;margin-left: 1em;" />

          <label style="margin-top: 10px;" > {{appConfig.appName}}</label>


          <span class="example-spacer"></span>

          <button mat-icon-button (click)="logoff()"  class="example-icon" aria-label="Example icon-button with share icon"> <mat-icon>logout</mat-icon> </button>

        </mat-toolbar>



          <div style="margin-left: 1em;">

            <router-outlet style="margin-top: 1em; margin-left: 2em;"></router-outlet>

          </div>

        </div>

        <spa-loader [logo]="this.dataService.appConfig.logo"></spa-loader>

      </mat-sidenav-content>

    </mat-sidenav-container>


    <!-- ########### This has issues with reload browser ####### -->
    <!-- <spa-timeout></spa-timeout> -->

  </div>






</body>



