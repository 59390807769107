import { HttpService } from 'tin-core';
import { DataService } from './services/data.service';
import { ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { AuthService, MessageService } from "tin-spa";
import { MatSidenav } from '@angular/material/sidenav';

// import { AppConfig } from 'tin-spa/public-api';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnDestroy {

  constructor(public router: Router,public dataService: DataService, private messageService: MessageService,  private httpService: HttpService, public authService: AuthService, changeDetectorRef: ChangeDetectorRef, media: MediaMatcher){

    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.authService.loggedUserFullName.subscribe(user => this.loggedUserFullName = user)
    this.authService.myRoleObserv.subscribe(rol => this.myRole = rol);
    this.authService.loggedinObserv.subscribe(obj => this.loggedin = obj)

  }



  ngOnInit() {

    this.appConfig = this.dataService.appConfig;
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  @ViewChild('sidenav') sidenav: MatSidenav;

  toggle(){

    this.sidenav.toggle();
  }

  icon = "navigate_next";
  title = 'wallet-spa';

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  fillerNav = Array.from({length: 50}, (_, i) => `Nav Item ${i + 1}`);

  appConfig;

  myRole;
  loggedUserFullName: string;
  loggedin: boolean = false;

  redirectTo(link: string){

    if (link!=""){
      this.router.navigate([link]);
    }

  }

  about(){
    // this.messageService.info("Something about us","To be populated")

  }

  profile(){
    this.redirectTo("home/user/profile")
  }

  logoff(){

    this.messageService.confirm(`Logout ?`).subscribe((result) => {
    if (result == "yes"){

      this.redirectTo("login")

    };
    });

    }

}
