import { HttpService } from 'tin-core';
import { Injectable } from '@angular/core';
// import {  Txn, Wallet, Transfer } from '../classes/TinWallet';
import { CapItem,  AppConfig } from 'tin-spa';
import { Allocation, Approval, ApprovalGroup, ApprovalRole, Car, CheckList, Customer, Department, Driver, Employee, Fund, Horse, Jobcard,
  Labour, LoadEx, Material, Position, StoreItem, Trailer, Trip, TripCost, TripFuel, Tyre, VehicleIssue } from '../classes/Classes';
// import * as conf from '../../assets/config.json';
import { Capabilities } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class DataService {

    constructor(private httpService: HttpService) {

    //API URL
    let url = new URL(window.location.href);

    if (url.host.includes('localhost')){
      httpService.apiUrl = "http://localhost:62636/";
    }else{
      // httpService.apiUrl = `${url.protocol}//${url.host}/api/`;
      httpService.apiUrl = `https://tin-trucker.azurewebsites.net/`;
    }



      //Role Capabilities

      this.appConfig.appName = "Ankins Trucker";
      this.appConfig.logo = "./assets/Ankins_logo.png"
      this.appConfig.ADAuth = false;
      this.appConfig.approvals = true;
      this.appConfig.employees= true;
      this.appConfig.capItems = [ this.capHome, this.capApprovals, this.capTrips, this. capLoads,  this.capCustomers,this.capStores, this.capDriver, this.capVehicles, this.capWorkshop, this.capHumanResources, this.capAdmin];


      this.capHome.name = "cap1";
      this.capHome.display = "Dashboard";
      this.capHome.link = "home";
      this.capHome.icon = "dashboard";


      //--Admin
      this.capAdmin.name = "cap2";
      this.capAdmin.display = "Admin";
      this.capAdmin.icon = "security";
      this.capAdmin.capSubItems = [this.capUsers, this.capRoles, this.capLogs, this.capSettings, this.capApprovalGroups, this.capApprovalRoles];

      this.capUsers.name = "cap3";
      this.capUsers.display = "Users";
      this.capUsers.link = "home/admin/users";

      this.capRoles.name = "cap4"
      this.capRoles.display = "Roles";
      this.capRoles.link = "home/admin/roles";

      this.capLogs.name = "cap5"
      this.capLogs.display = "Logs";
      this.capLogs.link = "home/admin/logs";

      this.capSettings.name = "cap6"
      this.capSettings.display = "Settings";
      this.capSettings.link = "home/admin/settings";

      this.capApprovalGroups.name = "cap7"
      this.capApprovalGroups.display = "Approval Perms";
      this.capApprovalGroups.link = "home/approvalgroups";

      this.capApprovalRoles.name = "cap8"
      this.capApprovalRoles.display = "Approval Roles";
      this.capApprovalRoles.link = "home/approvalroles";

      this.capApprovals.name = "cap9"
      this.capApprovals.display = "Approvals";
      this.capApprovals.link = "home/approvals";
      this.capApprovals.icon = "rule";



      this.capDriver.name = "cap20"
      this.capDriver.display = "Driver";
      this.capDriver.link = "home/driver";
      this.capDriver.icon = "airline_seat_recline_normal";




      //-----App Specific Roles---//


      //--Trip sheets
      this.capTrips.name = "cap21"
      this.capTrips.display = "Trips";
      this.capTrips.link = "home/trips";
      this.capTrips.icon = "edit_road";
      this.capTrips.ignoreSubsDisplay = true;
      this.capTrips.capSubItems = [this.capTrips_add, this.capTrips_booking,this.capTrips_allocation, this.capTrips_funding, this.capTrips_checkout, this.capTrips_checkin,
        this.capTrips_complete, this.capTrips_costs, this.capTrips_issues ]

      this.capTrips_add.name = "cap22"
      this.capTrips_add.display = "Trips_add";

      this.capTrips_booking.name = "cap23"
      this.capTrips_booking.display = "Trips_booking";

      this.capTrips_allocation.name = "cap24"
      this.capTrips_allocation.display = "Trips_allocation";

      this.capTrips_funding.name = "cap25"
      this.capTrips_funding.display = "Trips_funding";

      this.capTrips_checkout.name = "cap26"
      this.capTrips_checkout.display = "Trips_checkout";

      this.capTrips_checkin.name = "cap27"
      this.capTrips_checkin.display = "Trips_checkin";

      this.capTrips_complete.name = "cap28"
      this.capTrips_complete.display = "Trips_complete";

      this.capTrips_costs.name = "cap29"
      this.capTrips_costs.display = "Trips_costs";

      this.capTrips_issues.name = "cap30"
      this.capTrips_issues.display = "Trips_issues";





      //--Loads
      this.capLoads.name = "cap31"
      this.capLoads.display = "Loads";
      this.capLoads.link = "home/loads";
      this.capLoads.icon = "storage";
      this.capLoads.ignoreSubsDisplay = true;
      this.capLoads.capSubItems = [this.capLoads_add, this.capLoads_edit, this.capLoads_delete, this.capLoads_amount]

      this.capLoads_add.name = "cap32"
      this.capLoads_add.display = "Loads_add";
      this.capLoads_add.showMenu = false;

      this.capLoads_edit.name = "cap33"
      this.capLoads_edit.display = "Loads_edit";
      this.capLoads_edit.showMenu = false;

      this.capLoads_delete.name = "cap34"
      this.capLoads_delete.display = "Loads_delete";
      this.capLoads_delete.showMenu = false;

      this.capLoads_amount.name = "cap35"
      this.capLoads_amount.display = "Loads_amount";
      this.capLoads_amount.showMenu = false;


      //--Customers
      this.capCustomers.name = "cap36"
      this.capCustomers.display = "Customers";
      this.capCustomers.link = "home/customers";
      this.capCustomers.icon = "people";
      this.capCustomers.ignoreSubsDisplay = true;
      this.capCustomers.capSubItems = [this.capCustomers_add, this.capCustomers_edit, this.capCustomers_delete]

      this.capCustomers_add.name = "cap37"
      this.capCustomers_add.display = "Customers_add";
      this.capCustomers_add.showMenu = false;

      this.capCustomers_edit.name = "cap38"
      this.capCustomers_edit.display = "Customers_edit";
      this.capCustomers_edit.showMenu = false;

      this.capCustomers_delete.name = "cap39"
      this.capCustomers_delete.display = "Customers_delete";
      this.capCustomers_delete.showMenu = false;


      //--Stores
      this.capStores.name = "cap41"
      this.capStores.display = "Stores";
      this.capStores.link = "home/storeitems"; //storeitems
      this.capStores.icon = "store";
      this.capStores.ignoreSubsDisplay = true;
      this.capStores.capSubItems = [this.capStores_add, this.capStores_edit, this.capStores_delete, this.capStores_increase, this.capStores_decrease]

      this.capStores_add.name = "cap42"
      this.capStores_add.display = "Stores_add";

      this.capStores_edit.name = "cap43"
      this.capStores_edit.display = "Stores_edit";

      this.capStores_delete.name = "cap44"
      this.capStores_delete.display = "Stores_delete";

      this.capStores_increase.name = "cap45"
      this.capStores_increase.display = "Stores_increase";

      this.capStores_decrease.name = "cap46"
      this.capStores_decrease.display = "Stores_decrease";





      //--Vehicles
      this.capVehicles.name = "cap51";
      this.capVehicles.display = "Vehicles";
      this.capVehicles.icon = "directions_bus";
      this.capVehicles.capSubItems = [this.capHorses, this.capTrailers, this.capCars, this.capTyres];


      //--Horses
      this.capHorses.name = "cap52"
      this.capHorses.display = "Horses";
      this.capHorses.link = "home/horses";
      this.capHorses.ignoreSubsDisplay = true;
      this.capHorses.capSubItems = [this.capHorses_add, this.capHorses_edit, this.capHorses_delete]

      this.capHorses_add.name = "cap53"
      this.capHorses_add.display = "Horses_add";
      this.capHorses_add.showMenu = false;

      this.capHorses_edit.name = "cap54"
      this.capHorses_edit.display = "Horses_edit";
      this.capHorses_edit.showMenu = false;

      this.capHorses_delete.name = "cap55"
      this.capHorses_delete.display = "Horses_delete";
      this.capHorses_delete.showMenu = false;


      //--Trailers
      this.capTrailers.name = "cap56"
      this.capTrailers.display = "Trailers";
      this.capTrailers.link = "home/trailers";
      this.capTrailers.ignoreSubsDisplay = true;
      this.capTrailers.capSubItems = [this.capTrailers_add, this.capTrailers_edit, this.capTrailers_delete]

      this.capTrailers_add.name = "cap57"
      this.capTrailers_add.display = "Trailers_add";
      this.capTrailers_add.showMenu = false;

      this.capTrailers_edit.name = "cap58"
      this.capTrailers_edit.display = "Trailers_edit";
      this.capTrailers_edit.showMenu = false;

      this.capTrailers_delete.name = "cap59"
      this.capTrailers_delete.display = "Trailers_delete";
      this.capTrailers_delete.showMenu = false;


      //--Cars
      this.capCars.name = "cap61"
      this.capCars.display = "Cars";
      this.capCars.link = "home/cars";
      this.capCars.ignoreSubsDisplay = true;
      this.capCars.capSubItems = [this.capCars_add, this.capCars_edit, this.capCars_delete]

      this.capCars_add.name = "cap62"
      this.capCars_add.display = "Cars_add";
      this.capCars_add.showMenu = false;

      this.capCars_edit.name = "cap63"
      this.capCars_edit.display = "Cars_edit";
      this.capCars_edit.showMenu = false;

      this.capCars_delete.name = "cap64"
      this.capCars_delete.display = "Cars_delete";
      this.capCars_delete.showMenu = false;


      this.capTyres.name = "cap66"
      this.capTyres.display = "Tyres";
      this.capTyres.link = "home/tyres";




      //--Workshop
      this.capWorkshop.name = "cap71";
      this.capWorkshop.display = "Workshop";
      this.capWorkshop.icon = "build";
      this.capWorkshop.capSubItems = [this.capJobcards, this.capChecklists];

      //--Jobcards
      this.capJobcards.name = "cap72"
      this.capJobcards.display = "Jobcards";
      this.capJobcards.link = "home/jobcards";
      this.capJobcards.ignoreSubsDisplay = true;
      this.capJobcards.capSubItems = [this.capJobcards_add, this.capJobcards_edit, this.capJobcards_close]

      this.capJobcards_add.name = "cap73"
      this.capJobcards_add.display = "Jobcards_add";
      this.capJobcards_add.showMenu = false;

      this.capJobcards_edit.name = "cap74"
      this.capJobcards_edit.display = "Jobcards_edit";
      this.capJobcards_edit.showMenu = false;

      this.capJobcards_close.name = "cap75"
      this.capJobcards_close.display = "Jobcards_close";
      this.capJobcards_close.showMenu = false;

      //--Checklists
      this.capChecklists.name = "cap76"
      this.capChecklists.display = "Checklists";
      this.capChecklists.link = "home/checklists";
      this.capChecklists.ignoreSubsDisplay = true;
      this.capChecklists.capSubItems = [this.capChecklists_add, this.capChecklists_edit, this.capChecklists_close]

      this.capChecklists_add.name = "cap77"
      this.capChecklists_add.display = "Checklists_add";
      this.capChecklists_add.showMenu = false;

      this.capChecklists_edit.name = "cap78"
      this.capChecklists_edit.display = "Checklists_edit";
      this.capChecklists_edit.showMenu = false;

      this.capChecklists_close.name = "cap79"
      this.capChecklists_close.display = "Checklists_close";
      this.capChecklists_close.showMenu = false;


      //--HR
      this.capHumanResources.name = "cap81";
      this.capHumanResources.display = "HR";
      this.capHumanResources.icon = "badge";
      this.capHumanResources.capSubItems = [this.capEmployees,this.capDrivers, this.capDepartments, this.capPositions,];

      //--Employees
      this.capEmployees.name = "cap82"
      this.capEmployees.display = "Employees";
      this.capEmployees.link = "home/employees";
      this.capEmployees.ignoreSubsDisplay = true;
      this.capEmployees.capSubItems = [this.capEmployees_add, this.capEmployees_edit, this.capEmployees_delete]

      this.capEmployees_add.name = "cap83"
      this.capEmployees_add.display = "Employees_add";
      this.capEmployees_add.showMenu = false;

      this.capEmployees_edit.name = "cap84"
      this.capEmployees_edit.display = "Employees_edit";
      this.capEmployees_edit.showMenu = false;

      this.capEmployees_delete.name = "cap85"
      this.capEmployees_delete.display = "Employees_delete";
      this.capEmployees_delete.showMenu = false;

      //--Departments
      this.capDepartments.name = "cap91"
      this.capDepartments.display = "Departments";
      this.capDepartments.link = "home/departments";
      this.capDepartments.ignoreSubsDisplay = true;
      this.capDepartments.capSubItems = [this.capDepartments_add, this.capDepartments_edit, this.capDepartments_delete]

      this.capDepartments_add.name = "cap92"
      this.capDepartments_add.display = "Departments_add";
      this.capDepartments_add.showMenu = false;

      this.capDepartments_edit.name = "cap93"
      this.capDepartments_edit.display = "Departments_edit";
      this.capDepartments_edit.showMenu = false;

      this.capDepartments_delete.name = "cap94"
      this.capDepartments_delete.display = "Departments_delete";
      this.capDepartments_delete.showMenu = false;


      //--Positions
      this.capPositions.name = "cap96"
      this.capPositions.display = "Positions";
      this.capPositions.link = "home/positions";
      this.capPositions.ignoreSubsDisplay = true;
      this.capPositions.capSubItems = [this.capPositions_add, this.capPositions_edit, this.capPositions_delete]

      this.capPositions_add.name = "cap97"
      this.capPositions_add.display = "Positions_add";
      this.capPositions_add.showMenu = false;

      this.capPositions_edit.name = "cap98"
      this.capPositions_edit.display = "Positions_edit";
      this.capPositions_edit.showMenu = false;

      this.capPositions_delete.name = "cap99"
      this.capPositions_delete.display = "Positions_delete";
      this.capPositions_delete.showMenu = false;



      //--Drivers
      this.capDrivers.name = "cap86"
      this.capDrivers.display = "Drivers";
      this.capDrivers.link = "home/drivers";
      this.capDrivers.ignoreSubsDisplay = true;
      this.capDrivers.capSubItems = [this.capDrivers_add, this.capDrivers_edit, this.capDrivers_delete]

      this.capDrivers_add.name = "cap87"
      this.capDrivers_add.display = "Drivers_add";
      this.capDrivers_add.showMenu = false;

      this.capDrivers_edit.name = "cap88"
      this.capDrivers_edit.display = "Drivers_edit";
      this.capDrivers_edit.showMenu = false;

      this.capDrivers_delete.name = "cap89"
      this.capDrivers_delete.display = "Drivers_delete";
      this.capDrivers_delete.showMenu = false;









    }

    appConfig = new AppConfig();

    // caps: CapItem[];
    capHome = new CapItem;
    capAdmin = new CapItem;
    capUsers = new CapItem;
    capRoles = new CapItem;
    capLogs = new CapItem;
    capSettings = new CapItem;
    capApprovalGroups = new CapItem;
    capApprovalRoles = new CapItem;

    capStores = new CapItem;
    capStores_add = new CapItem;
    capStores_edit = new CapItem;
	  capStores_delete = new CapItem;
    capStores_increase = new CapItem;
    capStores_decrease = new CapItem;

    capVehicles = new CapItem;

    capHorses = new CapItem;
    capHorses_add = new CapItem;
    capHorses_edit = new CapItem;
    capHorses_delete = new CapItem;

    capTrailers = new CapItem;
    capTrailers_add = new CapItem;
    capTrailers_edit = new CapItem;
	  capTrailers_delete = new CapItem;

    capCars = new CapItem;
    capCars_add = new CapItem;
    capCars_edit = new CapItem;
	  capCars_delete = new CapItem;


    capTyres = new CapItem;
    capWorkshop = new CapItem;

    capJobcards = new CapItem;
    capJobcards_add = new CapItem;
    capJobcards_edit = new CapItem;
	  capJobcards_close = new CapItem;

    capChecklists = new CapItem;
    capChecklists_add = new CapItem;
    capChecklists_edit = new CapItem;
	  capChecklists_close = new CapItem;



    capDrivers = new CapItem;
    capDrivers_add = new CapItem;
    capDrivers_edit = new CapItem;
	  capDrivers_delete = new CapItem;

    capTrips = new CapItem;
    capTrips_add = new CapItem;
    capTrips_booking = new CapItem;
    capTrips_allocation = new CapItem;
    capTrips_funding = new CapItem;
    capTrips_checkout = new CapItem;
    capTrips_checkin = new CapItem;
    capTrips_complete = new CapItem;
    capTrips_costs = new CapItem;
    capTrips_issues = new CapItem;




    capHumanResources = new CapItem;

    capEmployees = new CapItem;
    capEmployees_add = new CapItem;
    capEmployees_edit = new CapItem;
	  capEmployees_delete = new CapItem;

    capDepartments = new CapItem;
    capDepartments_add = new CapItem;
    capDepartments_edit = new CapItem;
	  capDepartments_delete = new CapItem;

    capPositions = new CapItem;
    capPositions_add = new CapItem;
    capPositions_edit = new CapItem;
	  capPositions_delete = new CapItem;

    capCustomers = new CapItem;
    capCustomers_add = new CapItem;
    capCustomers_edit = new CapItem;
	  capCustomers_delete = new CapItem;


    capDriver = new CapItem;

    capLoads = new CapItem;
    capLoads_add = new CapItem;
    capLoads_edit = new CapItem;
	  capLoads_delete = new CapItem;
    capLoads_amount = new CapItem;

    capApprovals = new CapItem;



    temp: string = "";
    tempJobcardID = "";
    tempTripID = "";
    tempFrom = "";
    tempCheckID = "";






    // Storeitems
UpdateStoreItem(storeitem: StoreItem, action: string) {
  return this.httpService.Post('Trucker/UpdateStoreItem?action=' + action, storeitem);
  }


  GetStoreItem(by: string, val: string) {
  return this.httpService.Get('Trucker/GetStoreItem?by=' + by + '&val=' + val);
  }

  GetStoreHistory(by: string, val: string) {
    return this.httpService.Get('Trucker/GetStoreHistory?by=' + by + '&val=' + val);
    }


    // Horses
UpdateHorse(horse: Horse, action: string) {
  return this.httpService.Post('Trucker/UpdateHorse?action=' + action, horse);
  }


  GetHorse(by: string, val: string) {
  return this.httpService.Get('Trucker/GetHorse?by=' + by + '&val=' + val);
  }


  // Trailers
UpdateTrailer(trailer: Trailer, action: string) {
  return this.httpService.Post('Trucker/UpdateTrailer?action=' + action, trailer);
  }


  GetTrailer(by: string, val: string) {
  return this.httpService.Get('Trucker/GetTrailer?by=' + by + '&val=' + val);
  }

  // Jobcards
UpdateJobcard(jobcards: Jobcard, action: string) {
  return this.httpService.Post('Trucker/UpdateJobcard?action=' + action, jobcards);
  }


  GetJobcard(by: string, val: string) {
  return this.httpService.Get('Trucker/GetJobcard?by=' + by + '&val=' + val);
  }



  // Labour
UpdateLabour(labour: Labour, action: string) {
  return this.httpService.Post('Trucker/UpdateLabour?action=' + action, labour);
  }


  GetLabour(by: string, val: string) {
  return this.httpService.Get('Trucker/GetLabour?by=' + by + '&val=' + val);
  }

  // Materials
UpdateMaterial(material: Material, action: string) {
  return this.httpService.Post('Trucker/UpdateMaterial?action=' + action, material);
  }


  GetMaterial(by: string, val: string) {
  return this.httpService.Get('Trucker/GetMaterial?by=' + by + '&val=' + val);
  }


// Employees
UpdateEmployee(employee: Employee, action: string) {
  return this.httpService.Post('Trucker/UpdateEmployee?action=' + action, employee);
  }


  GetEmployee(by: string, val: string) {
  return this.httpService.Get('Trucker/GetEmployee?by=' + by + '&val=' + val);
  }


  // Drivers
UpdateDriver(driver: Driver, action: string) {
  return this.httpService.Post('Trucker/UpdateDriver?action=' + action, driver);
  }


  GetDriver(by: string, val: string) {
  return this.httpService.Get('Trucker/GetDriver?by=' + by + '&val=' + val);
  }


  // Trips
UpdateTrip(trip: Trip, action: string) {
  return this.httpService.Post('Trucker/UpdateTrip?action=' + action, trip);
  }


  GetTrip(by: string, val: string) {
  return this.httpService.Get('Trucker/GetTrip?by=' + by + '&val=' + val);
  }


  // Loads
UpdateLoad(load: LoadEx, action: string) {
  return this.httpService.Post('Trucker/UpdateLoad?action=' + action, load);
  }

  EditLoad(formData: FormData){
    return this.httpService.Post_FormData('Trucker/EditLoad', formData);
  }

  GetLoad(by: string, val: string) {
  return this.httpService.Get('Trucker/GetLoad?by=' + by + '&val=' + val);
  }

  // Checklists
UpdateCheckList(checkList: CheckList, action: string) {
  return this.httpService.Post('Trucker/UpdateCheckList?action=' + action, checkList);
  }


  GetCheckList(by: string, val: string) {
  return this.httpService.Get('Trucker/GetCheckList?by=' + by + '&val=' + val);
  }


  // Allocations
UpdateAllocation(allocation: Allocation, action: string) {
  return this.httpService.Post('Trucker/UpdateAllocation?action=' + action, allocation);
  }


  GetAllocation(by: string, val: string) {
  return this.httpService.Get('Trucker/GetAllocation?by=' + by + '&val=' + val);
  }


// Tripcosts
UpdateTripCost(tripCosts: TripCost, action: string) {
  return this.httpService.Post('Trucker/UpdateTripCost?action=' + action, tripCosts);
  }


  GetTripCost(by: string, val: string) {
  return this.httpService.Get('Trucker/GetTripCost?by=' + by + '&val=' + val);
  }


  // Vehicleissues
UpdateVehicleIssue(vehicleIssue: VehicleIssue, action: string) {
  return this.httpService.Post('Trucker/UpdateVehicleIssue?action=' + action, vehicleIssue);
  }


  GetVehicleIssue(by: string, val: string) {
  return this.httpService.Get('Trucker/GetVehicleIssue?by=' + by + '&val=' + val);
  }


  // Departments
  UpdateDepartment(department: Department, action: string) {
  return this.httpService.Post('Trucker/UpdateDepartment?action=' + action, department);
  }


  GetDepartment(by: string, val: string) {
  return this.httpService.Get('Trucker/GetDepartment?by=' + by + '&val=' + val);
  }


  // Positions
  UpdatePosition(position: Position, action: string) {
  return this.httpService.Post('Trucker/UpdatePosition?action=' + action, position);
  }


  GetPosition(by: string, val: string) {
  return this.httpService.Get('Trucker/GetPosition?by=' + by + '&val=' + val);
  }


// Customers
UpdateCustomer(customer: Customer, action: string) {
  return this.httpService.Post('Trucker/UpdateCustomer?action=' + action, customer);
  }


  GetCustomer(by: string, val: string) {
  return this.httpService.Get('Trucker/GetCustomer?by=' + by + '&val=' + val);
  }

  // Tyres
UpdateTyre(tyre: Tyre, action: string) {
  return this.httpService.Post('Trucker/UpdateTyre?action=' + action, tyre);
  }


  GetTyre(by: string, val: string) {
  return this.httpService.Get('Trucker/GetTyre?by=' + by + '&val=' + val);
  }


// Cars
UpdateCar(car: Car, action: string) {
  return this.httpService.Post('Trucker/UpdateCar?action=' + action, car);
  }


  GetCar(by: string, val: string) {
  return this.httpService.Get('Trucker/GetCar?by=' + by + '&val=' + val);
  }




  // Approvals
UpdateApproval(approval: Approval, action: string) {
  return this.httpService.Post('Trucker/UpdateApproval?action=' + action, approval);
  }


  GetApproval(by: string, val: string) {
  return this.httpService.Get('Trucker/GetApproval?by=' + by + '&val=' + val);
  }

  // Approvalgroups
UpdateApprovalGroup(approvalGroup: ApprovalGroup, action: string) {
  return this.httpService.Post('Basic/UpdateApprovalGroup?action=' + action, approvalGroup);
  }


  GetApprovalGroup(by: string, val: string) {
  return this.httpService.Get('Basic/GetApprovalGroup?by=' + by + '&val=' + val);
  }


  // Approvalroles
UpdateApprovalRole(approvalRole: ApprovalRole, action: string) {
  return this.httpService.Post('Basic/UpdateApprovalRole?action=' + action, approvalRole);
  }


  GetApprovalRole(by: string, val: string) {
  return this.httpService.Get('Basic/GetApprovalRole?by=' + by + '&val=' + val);
  }


  // Tripfuels
  UpdateTripFuel(tripfuel: TripFuel, action: string) {
    return this.httpService.Post('Trucker/UpdateTripFuel?action=' + action, tripfuel);
  }


  GetTripFuel(by: string, val: string) {
    return this.httpService.Get('Trucker/GetTripFuel?by=' + by + '&val=' + val);
  }


  // Funds
  UpdateFund(fund: Fund, action: string) {
    return this.httpService.Post('Trucker/UpdateFund?action=' + action, fund);
  }


  GetFund(by: string, val: string) {
    return this.httpService.Get('Trucker/GetFund?by=' + by + '&val=' + val);
  }


}
